.about_company--lf-img img {
    object-fit: contain !important;
}



// .rll-youtube-player{
//     padding: unset !important;
//     width: 100% !important;
//     height: 100% !important;
//     position: absolute !important;
//     inset: 0 !important;
// }